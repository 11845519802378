import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name parking-meter
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTEgMTVoMiIgLz4KICA8cGF0aCBkPSJNMTIgMTJ2MyIgLz4KICA8cGF0aCBkPSJNMTIgMTl2MyIgLz4KICA8cGF0aCBkPSJNMTUuMjgyIDE5YTEgMSAwIDAgMCAuOTQ4LS42OGwyLjM3LTYuOTg4YTcgNyAwIDEgMC0xMy4yIDBsMi4zNyA2Ljk4OGExIDEgMCAwIDAgLjk0OC42OHoiIC8+CiAgPHBhdGggZD0iTTkgOWEzIDMgMCAxIDEgNiAwIiAvPgo8L3N2Zz4K) - https://lucide.dev/icons/parking-meter
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const ParkingMeter: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M11 15h2' }],
    ['path', { d: 'M12 12v3' }],
    ['path', { d: 'M12 19v3' }],
    [
      'path',
      {
        d: 'M15.282 19a1 1 0 0 0 .948-.68l2.37-6.988a7 7 0 1 0-13.2 0l2.37 6.988a1 1 0 0 0 .948.68z',
      },
    ],
    ['path', { d: 'M9 9a3 3 0 1 1 6 0' }],
  ],
];

export default ParkingMeter;
