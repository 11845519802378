import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name headphone-off
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMjEgMTRoLTEuMzQzIiAvPgogIDxwYXRoIGQ9Ik05LjEyOCAzLjQ3QTkgOSAwIDAgMSAyMSAxMnYzLjM0MyIgLz4KICA8cGF0aCBkPSJtMiAyIDIwIDIwIiAvPgogIDxwYXRoIGQ9Ik0yMC40MTQgMjAuNDE0QTIgMiAwIDAgMSAxOSAyMWgtMWEyIDIgMCAwIDEtMi0ydi0zIiAvPgogIDxwYXRoIGQ9Ik0zIDE0aDNhMiAyIDAgMCAxIDIgMnYzYTIgMiAwIDAgMS0yIDJINWEyIDIgMCAwIDEtMi0ydi03YTkgOSAwIDAgMSAyLjYzNi02LjM2NCIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/headphone-off
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const HeadphoneOff: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M21 14h-1.343' }],
    ['path', { d: 'M9.128 3.47A9 9 0 0 1 21 12v3.343' }],
    ['path', { d: 'm2 2 20 20' }],
    ['path', { d: 'M20.414 20.414A2 2 0 0 1 19 21h-1a2 2 0 0 1-2-2v-3' }],
    [
      'path',
      { d: 'M3 14h3a2 2 0 0 1 2 2v3a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-7a9 9 0 0 1 2.636-6.364' },
    ],
  ],
];

export default HeadphoneOff;
