import defaultAttributes from '../defaultAttributes';
import type { IconNode } from '../types';

/**
 * @name combine
 * @description Lucide SVG icon node.
 *
 * @preview ![img](data:image/svg+xml;base64,PHN2ZyAgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIgogIHdpZHRoPSIyNCIKICBoZWlnaHQ9IjI0IgogIHZpZXdCb3g9IjAgMCAyNCAyNCIKICBmaWxsPSJub25lIgogIHN0cm9rZT0iIzAwMCIgc3R5bGU9ImJhY2tncm91bmQtY29sb3I6ICNmZmY7IGJvcmRlci1yYWRpdXM6IDJweCIKICBzdHJva2Utd2lkdGg9IjIiCiAgc3Ryb2tlLWxpbmVjYXA9InJvdW5kIgogIHN0cm9rZS1saW5lam9pbj0icm91bmQiCj4KICA8cGF0aCBkPSJNMTAgMThINWEzIDMgMCAwIDEtMy0zdi0xIiAvPgogIDxwYXRoIGQ9Ik0xNCAyYTIgMiAwIDAgMSAyIDJ2NGEyIDIgMCAwIDEtMiAyIiAvPgogIDxwYXRoIGQ9Ik0yMCAyYTIgMiAwIDAgMSAyIDJ2NGEyIDIgMCAwIDEtMiAyIiAvPgogIDxwYXRoIGQ9Im03IDIxIDMtMy0zLTMiIC8+CiAgPHJlY3QgeD0iMTQiIHk9IjE0IiB3aWR0aD0iOCIgaGVpZ2h0PSI4IiByeD0iMiIgLz4KICA8cmVjdCB4PSIyIiB5PSIyIiB3aWR0aD0iOCIgaGVpZ2h0PSI4IiByeD0iMiIgLz4KPC9zdmc+Cg==) - https://lucide.dev/icons/combine
 * @see https://lucide.dev/guide/packages/lucide - Documentation
 *
 * @returns {Array}
 *
 */
const Combine: IconNode = [
  'svg',
  defaultAttributes,
  [
    ['path', { d: 'M10 18H5a3 3 0 0 1-3-3v-1' }],
    ['path', { d: 'M14 2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2' }],
    ['path', { d: 'M20 2a2 2 0 0 1 2 2v4a2 2 0 0 1-2 2' }],
    ['path', { d: 'm7 21 3-3-3-3' }],
    ['rect', { x: '14', y: '14', width: '8', height: '8', rx: '2' }],
    ['rect', { x: '2', y: '2', width: '8', height: '8', rx: '2' }],
  ],
];

export default Combine;
